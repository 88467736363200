import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHref, useNavigate } from 'react-router-dom'
import { changePasswordEmail, createPassword, registerEmail, resetPassword, sendEmailCodeHttp } from '../host/requests/Auth'
import { checkResponse } from '../host/Host'
import { dangerAlert, successAlert } from '../components/Alert'
import { useStore } from '../store/Store'
import login_img from '../new_images/main/mobile_back_img_change.jpg'
import FooterMobile from '../components/FooterMobile'
import { useEffect } from 'react'

export default function ChangePassword() {
  const [form] = Form.useForm();
  
    const [type, setType]=useState(1)
   
    const [email, setemail]=useState(null)
    const [confirm_password, setconfirm_password]=useState(null)
    const [password, setpassword]=useState(null)
    const [created, setcreated]=useState(false)
    const token_access=useStore(state=>state.token_access)
    const setloader=useStore(state=>state.setloader)
    const user=useStore(state=>state.user)
    const {t}=useTranslation()
    const navigate = useNavigate();
   const emailForm=form;
   const codeForm=form;
    const  onFinish=async(val)=>{
      val.email=email
      val.password=password
      val.confirm_password=confirm_password
      setloader(true)
     try{
        var res=await resetPassword(val, token_access)
        
        codeForm.resetFields()
        setemail(null)
        successAlert(t("password_changed"))
        setcreated(true)
        setloader(false)
        navigate("/login")
        
      }catch (error){
        console.log(error, !!error.response.data.detail)
        if(!!error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("error_password"))
        }
        setloader(false)
      }
    }

    const getEmailCode=async(val)=>{
      if(val.password===val.confirm_password){
        setloader(true)
        setemail(val.email)
        setpassword(val.password)
        setconfirm_password(val.confirm_password)
        try{
          var res=await changePasswordEmail(val)
  
          successAlert(t("send_code_email"))
          emailForm.resetFields()
          
          setType(2)
         
          setloader(false)
        }catch (error){
          if(error.response.data.detail){
            dangerAlert(t(error.response.data.detail))
          }else{
            dangerAlert(t("send_code_email_error"))
  
          }
          setloader(false)
        }
      }else{
        dangerAlert(t("error_new_password"))
      }
      
   }
   useEffect(()=>{
    if(user!=null){
      navigate('/cabinet')
    }
   }, [])
  return (
    <>
    <div className='login_big_box'>
   
           {type===1?
              <div className='login_form'>
    <h1>{t("change_password")}</h1>
    <p>{t("change_text")}</p>
         <Form
     form={emailForm}
     name="emailForm"
     labelCol={{
       span: 24,
     }}
     wrapperCol={{
       span: 24,
     }}
    initialValues={{
       remember: true,
     }}
     onFinish={getEmailCode}
     
     // onFinishFailed={onFinishFailed}
     autoComplete="new-password"
   >
     <Form.Item
       name="email"
       rules={[
         {
           type: 'email',
           message: t("not_email"),
         },
         {
            required: true,
            message: t("not_empty"),
             
         }
       ]}
     >
       <Input placeholder={t("email")}  autoComplete="new-password"/>
     </Form.Item>
     <Form.Item
 
  name="password"
  rules={[
    {
      required: true,
      message: t("not_empty"),
    },
  ]}
>
  <Input.Password  placeholder={t("password")}  autoComplete="new-password"/>
</Form.Item>
<Form.Item
 
  name="confirm_password"
  rules={[
    {
      required: true,
      message: t("not_empty"),
    },
  ]}
>
  <Input.Password placeholder={t("check_password")} onPaste={(e) => {e.preventDefault()}}  autoComplete="new-password"/>
</Form.Item>
     <Form.Item
       wrapperCol={{
         span: 24,
       }}
     >
       <Button  className='btn_form' type="primary" htmlType="submit">
         {t("send")}
       </Button>
     </Form.Item>
   </Form>
   <div className='login_bottom flex_left'>
        <Link className='forget_link' to="/login"><p dangerouslySetInnerHTML={{__html:t("has_account")}} /></Link>
        </div>
      </div>  
           :type===2?
           <div className='login_form'>
    <h1>{t("enter_code")}</h1>
       <Form
   name="basic1"
   labelCol={{
     span: 24,
   }}
   wrapperCol={{
     span: 24,
   }}
  initialValues={{
     remember: true,
   }}
   onFinish={onFinish}
   form={codeForm}
   
   autoComplete="new-password"
 >
  <Form.Item
  name="code"
  rules={[
    {
      required: true,
      message: t("not_empty"),
    },
  ]}
>
<Input.OTP  formatter={(str) => str.toUpperCase()} />

</Form.Item>

<div className='login_bottom flex_left'>
  <p className='login_bottom_text' dangerouslySetInnerHTML={{__html:t("not_code")}}></p>
  </div>
  <Form.Item
     wrapperCol={{
       span: 24,
     }}
   >
     <Button className='btn_form' type="primary" htmlType="submit">
       {t("send")}
     </Button>
   </Form.Item>
 </Form>
 
</div>
       :<></>}
        
        
         <div className='login_img'>
               <img src={login_img} />
           </div>
           
      </div>
      <FooterMobile/></>
  )
}
