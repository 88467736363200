import React, { useState } from 'react'
import { useStore } from '../store/Store'

import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export default function ChatActivate({open_active}) {
    const {chat_id}=useParams()
const open_chat=useStore(state=>state.open_chat)
const chat_data=useStore(state=>state.chat_data)
const user=useStore(state=>state.user)
const chat_number=useStore(state=>state.chat_number)
const navigate =useNavigate()
const location =useLocation()
const [need_activation, setneed_activation]=useState(false)
const [expired_at, setexpired_at]=useState(null)
const [need_activation_other, setneed_activation_other]=useState(false)

const [limit, setlimit]=useState(false)
const [limit_other, setlimit_other]=useState(false)
const {t}=useTranslation()
useEffect(()=>{
  console.log('salom', open_chat, chat_number)
  setneed_activation(false)
  setlimit(0)
     console.log(open_chat, chat_data, 'YYYYYYYYYYYYYYYYY')
     if(chat_data && chat_data[user.id]){
      if(chat_data[user.id].type==="MessageLimit"){
        if(chat_data[user.id].limit===0){
          setneed_activation(true)
          setlimit(false)
        }else{
          setneed_activation(false)
          setlimit(true)
          console.log("salom2")
        }
    }else if(chat_data[user.id].type==="TarifActivation"){
      const dateStr =chat_data[user.id].expired_at.trim();
    const [datePart, timePart] = dateStr.split(" ");
const [day, month, year] = datePart.split("-").map(Number);
const [hours, minutes, seconds] = timePart.split(":").map(Number);
const date = new Date(year, month - 1, day, hours, minutes, seconds);
const now = new Date();
const isAfterNow = date > now; 
      if(isAfterNow){
        setneed_activation(false)
        setexpired_at(date.toLocaleString())
      }else{
        setneed_activation(true)
        setexpired_at(null)
      }
      
      setlimit(false)
    }else{
      setneed_activation(true)
      setexpired_at(null)
    }
   }else{
    setlimit(true)
    setexpired_at(null)
  }


  if(chat_data && chat_data[open_chat.other_user.id]){
    if(chat_data[open_chat.other_user.id].type==="MessageLimit"){
      if(chat_data[open_chat.other_user.id].limit===0){
        setneed_activation_other(true)
        setlimit_other(false)
      }else{
        setneed_activation_other(false)
        setlimit_other(true)
      }
  }else if(chat_data[open_chat.other_user.id].type==="TarifActivation"){
    const dateStr =chat_data[open_chat.other_user.id].expired_at.trim();
    const [datePart, timePart] = dateStr.split(" ");
const [day, month, year] = datePart.split("-").map(Number);
const [hours, minutes, seconds] = timePart.split(":").map(Number);
const date = new Date(year, month - 1, day, hours, minutes, seconds);
const now = new Date();
const isAfterNow = date > now; 
    if(isAfterNow){
      setneed_activation_other(false)
    }else{
      setneed_activation_other(true)
    }
    
    setlimit_other(false)
  }else{
    setneed_activation_other(true)
  }
 }else{
  setlimit_other(true)
}
 
 
}, [chat_number])
  return (
    open_chat!==null?
      <div className={`chat_list chat_activate ${open_active?"open_activate":''}`}>
        <div className='chat_list_header'>{t("plan_status")}</div>
        {/* <div className='activate_items'>
        <div className={`activate_item ${need_activation?"danger_user_box":limit?"warning_user_box":'success_user_box'}`}>
        <div className={`user_box`}>
       <div className='avatar'>
       <div className='avatar_box' style={{backgroundImage:`url(${user!==null && user.main_image!==null?user.main_image.media:user.gender=='male'?man_icon:woman_icon})`}}></div>
       </div>
       <div className='username'>
            <h1>{user!==null?user.first_name:""}</h1>
            <span className='active_status'>{need_activation?t("plan_expired"):limit?t("five_message_free"):t("active_plan")}</span>
       </div>
     </div>
     
     {need_activation?
     <div className='activate_data'>
      <Link  className='btn_active' to={'/cabinet/plans/'+open_chat.id+"/"+user.id+"/"+open_chat.name}>{t("activate_plan")}</Link>
     </div>
     :!limit?<div className='active_expired'>{t("tarif_expired_text")} : {expired_at}</div>:<></>}
     </div>
    
     <div className={`activate_item ${need_activation_other?"danger_user_box":limit_other?"warning_user_box":'success_user_box'}`}>
        <div className={`user_box`}>
       <div className='avatar'>
       <div className='avatar_box' style={{backgroundImage:`url(${open_chat.other_user!==null && open_chat.other_user.main_image!==null?open_chat.other_user.main_image:open_chat.other_user.gender=='male'?man_icon:woman_icon})`}}></div>
       </div>
       <div className='username'>
            <h1>{open_chat.other_user!==null?open_chat.other_user.first_name:""}</h1>
            <span className='active_status'>{need_activation_other?t("plan_expired"):limit_other?t("five_message_free"):t("active_plan")}</span>
       </div>
     </div>
     
     {need_activation_other?
     <div className='activate_data'>
      <Link  className='btn_active' to={'/cabinet/plans/'+open_chat.id+"/"+open_chat.other_user.id+"/"+open_chat.name}>{t("activate_plan")}</Link>
     </div>
     :<></>}
     </div>
     
        </div> */}
    </div>
      :<></>
    
  )
}
