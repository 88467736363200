import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { useTranslation } from 'react-i18next'
import { Link, useHref, useLocation, useNavigate, useParams } from 'react-router-dom'
import { MdHome, MdOutlineLogout} from 'react-icons/md'
import { getChatUsers, getLikeUsers, getdark_listUsers } from '../host/requests/User'
import useWebSocket from 'react-use-websocket'
import { FaDollarSign, FaLock, FaRegEnvelope, FaRegHeart } from 'react-icons/fa6'
import man_icon from '../new_images/man_icon.png'
import menu_back from '../new_images/menu_back.png'
import woman_icon from '../new_images/woman_icon.png'
import { FiEdit, FiUserX } from 'react-icons/fi';
import Cookies from 'js-cookie';
import { IoSettingsSharp } from 'react-icons/io5';
import { GiTakeMyMoney } from "react-icons/gi";
export default function Menu() {
  const {t} =useTranslation()
  
    const token_access=useStore(state=>state.token_access)
    const setlike_page=useStore(state=>state.setlike_page)
    const setlikes=useStore(state=>state.setlikes)
    const open_menu=useStore(state=>state.open_menu)
    const setdark_list_page=useStore(state=>state.setdark_list_page)
    const setopen_menu=useStore(state=>state.setopen_menu)
    const setdark_lists=useStore(state=>state.setdark_lists)
    const setchat_page=useStore(state=>state.setchat_page)
    const setchats=useStore(state=>state.setchats)
    const editchats=useStore(state=>state.editchats)
    const blockchat=useStore(state=>state.blockchat)
    const setlike_next=useStore(state=>state.setlike_next)
    const setdark_list_next=useStore(state=>state.setdark_list_next)
    const setchatsAll=useStore(state=>state.setchatsAll)
    const like_next=useStore(state=>state.like_next)
    const likes=useStore(state=>state.likes)
    const like_count=useStore(state=>state.like_count)
    const like_page=useStore(state=>state.like_page)
    const dark_list_next=useStore(state=>state.dark_list_next)
    const dark_lists=useStore(state=>state.dark_lists)
    const dark_list_page=useStore(state=>state.dark_list_page)
    const setchat_next=useStore(state=>state.setchat_next)
    const chat_next=useStore(state=>state.chat_next)
    const settoken_access=useStore(state=>state.settoken_access)
    const setuser=useStore(state=>state.setuser)
    const chats=useStore(state=>state.chats)
    const addlikes=useStore(state=>state.addlikes)
    const chat_count=useStore(state=>state.chat_count)
    const setchat_count=useStore(state=>state.setchat_count)
    const chat_page=useStore(state=>state.chat_page)
    const setopen_chat=useStore(state=>state.setopen_chat)
    const editOnlineUser=useStore(state=>state.editOnlineUser)
    
    const user=useStore(state=>state.user)
    
    const navigate=useNavigate()
    let location = useLocation();
    const { sendJsonMessage, lastMessage, readyState } = useWebSocket( `wss://api.bliss-link.com/ws/notifications/`,
    {
         queryParams: {
                token: token_access,
              },
              onOpen: () => {
                console.log("Connected!");
              },
              onClose: (error) => {
              
                console.log("Disconnected!", error);
              },
              onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data)

                switch (data.type) {
                  case "unread_count":
                    console.log("Hello3")
                    setchat_count(data.unread_count)
                    break;
                    case "new_message_notification":
                      editchats(data.conversation, data.conversation_name, 1)
                    break;
                    case "chat_blocked":
                      blockchat(data.conversation)
                    break;
                    case "chat_deleted":
                      var a=chats
                      var b=null
                      a.map((item, key)=>{
                        if(item.name===data.conversation){
                          b=key
                        }
                      })
                      if(b!==null){
                        if(chat_count-a[b].unread_messages>0){
                          console.log("Hello4")
                          setchat_count(chat_count-a[b].unread_messages)
                        }else{
                          setchat_count(0)
                        }
                        
                        a.splice(b, 1)
                        setchatsAll(a)
                        navigate("/cabinet")
                      }
                    break;
                    case "user_leave":
                      editOnlineUser(false, data.conversation_name, data.last_login)
                      break;
                      case "liked":
                       
                        addlikes(data)
                        break;
                        case "user_join":
                          editOnlineUser(true, data.conversation_name, data.last_login)
                          break;
                  default:
                    console.error("Unknown message type!");
                    break;
                }
              },
             
    });
    useEffect(()=>{
      getLikes()
      getdark_lists()
      getChats()
    
  
    },[token_access])

    const getChats=async()=>{
      if(chat_next!==null){
       
        try{
          var res=await getChatUsers({page:chat_page}, token_access)
        
          setchat_next(res.data.next)
          setchat_page(chat_page+1)
          var g=res.data.results
        g=g.filter((x)=>(x.other_user!=null && x.other_user.id!=null))
        setchats(g)
         
       }catch(error){
          console.log(error)
         
       }
      }
    }
    
    const getLikes=async()=>{
      if(like_next!==null){
      
        try{
          var res=await getLikeUsers({page:like_page}, token_access)
        
          setlike_next(res.data.next)
          setlike_page(like_page+1)
          setlikes(res.data.results)
         
       }catch(error){
          console.log(error)
         
       }
      }
    }
    const getdark_lists=async()=>{
      if(dark_list_next!==null){
       
        try{
          var res=await getdark_listUsers({page:dark_list_page}, token_access)
        
          setdark_list_next(res.data.next)
          setdark_list_page(dark_list_page+1)
          setdark_lists(res.data.results)
         
       }catch(error){
          console.log(error)
         
       }
      }
    }
const exitFunc=()=>{
  Cookies.remove('refresh_token_bez');
  Cookies.remove('access_token_bez');
  settoken_access(null)
  setuser(null)
  window.location.href=window.location.href
  
}
   
const [swipeDirection, setSwipeDirection] = useState('');

  let touchStartX = 0;
  let touchEndX = 0;

  // Function to handle the start of the touch event
  const handleTouchStart = (e) => {
    touchStartX = e.targetTouches[0].clientX;  // Record the starting X position
  };

  // Function to handle the end of the touch event
  const handleTouchEnd = () => {
   
    if (touchEndX - touchStartX > 50) {
      setopen_menu(false)  // Swiped right
    } 
  };

  // Function to track the touch movement
  const handleTouchMove = (e) => {
    touchEndX = e.targetTouches[0].clientX;  // Record the ending X position
  };

  return (
    <div  onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd} className={`menuDiv ${open_menu?"open_menu":""}`}>
      <img src={menu_back} className='menu_back' />
      <div className='menu_body'>
      <div className='user_img' style={{backgroundImage:`url(${user!=null?user.main_image!=null?user.main_image.media:user.gender==='male'?man_icon:woman_icon:null})`}}></div>
      <h1 className='user_name'>{user!==null?user.first_name+", "+user.age:""}</h1>
      <div className='menu_list'>
      <Link to={'/cabinet/profile'} className='menu_item'>
        <span><FiEdit /></span><p>{t("edit_profile")}</p>
        </Link>
        <Link to={'/cabinet/chat/all'} className='menu_item'>
        <span><FaRegEnvelope /></span><p>{t("messages")}</p>{chat_count>0?<div className='menu_badge'>{chat_count}</div>:<></>}
        </Link>
        <Link to={'/cabinet/likes'} className='menu_item'>
        <span><FaRegHeart /></span><p>{t("likes")}</p>{like_count>0?<div className='menu_badge'>{like_count}</div>:<></>}
        </Link>
        <Link to={'/cabinet/blocks'} className='menu_item'>
        <span><FiUserX /></span><p>{t("black_list")}</p>
        </Link>
        <Link to={'/cabinet/payments'} className='menu_item'>
        <span><FaDollarSign /></span><p>{t("payments")}</p>
        </Link>
        <Link to={'/cabinet/setting'} className='menu_item'>
        <span><IoSettingsSharp /></span><p>{t("account_settings")}</p>
        </Link>
        <div style={{cursor:'pointer'}} onClick={exitFunc} className='menu_item'>
        <span><MdOutlineLogout /></span><p>{t("exit")}</p>
        </div>
      </div>
     
      </div>
      
    </div>
  )
}
