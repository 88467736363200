
import { useTranslation } from 'react-i18next'
import { offerta } from './Data';
import { Button, Form, Input, Modal } from 'antd';

import { useStore } from '../store/Store';
import { api } from '../host/Host';
import axios from 'axios';
import { dangerAlert, successAlert } from './Alert';
import { useState } from 'react';

export default function FooterMobile() {
  const {t}=useTranslation()
    const setloader=useStore(state=>state.setloader)
    const [show, setshow]=useState(false)
    const [form] = Form.useForm();
    const onFinish=(val)=>{
      setloader(true)
          axios.post(`${api}/contacts/create/`, val).then(res=>{
            successAlert(t("data_sended"))
            setloader(false)
           closeModal()
          }).catch(err=>{
            setloader(false)
            dangerAlert(t('server_error_text'))
          })
    }
    const closeModal=()=>{
      setshow(false)
      form.setFieldsValue({
        name:'',
        text:'',
        contact:'',
      })

    }
  return (
    <>
    <div className='footer_mobile'>
       <p>{t("footer_text_mobile")} <a href={offerta[t('error_api')]} target='_blank'>{t("footer_link_mobile")}</a></p>
       
    </div>
    
    </>
  )
}
